const ChatSubsriptions = `
  subscription MySubscription ($id: String!){
    incomingChat (id: $id) {
      chatId
      id
      isAdmin
      message
      receiver
      sendAt
      sender
      sk
    }
  }
`;

const SendChat = `
  mutation MyMutation ($id: String!, $brandId: String! $message: String!) {
    sendChat(input: {id: $id, brandId: $brandId, message: $message}) {
      chatId
      id
      isAdmin
      message
      receiver
      brandId
      sendAt
      sender
      sk
    }
  }
`;

const GetAllChat = `
  query MyQuery ($id: ID!, $otherUser: String) {
    getChatRoom(id: $id, otherUser: $otherUser) {
      chats {
        chatId
        id
        brandId
        isAdmin
        message
        receiver
        sendAt
        sender
        sk
      }
      nextToken
    }
  }
`;

const GetAllRoom = `
  query MyQuery ($id: ID!) {
    getRoomList(id: $id) {
      data {
        pk
        sk
        name
        icon
      }
    }
  }
`;

const GetAllDMs = `
  query MyQuery ($count: Int, $id: ID!) {
    getDMList(count: $count, id: $id) {
      chats {
        chatId
        expDate
        id
        isAdmin
        message
        receiver
        sendAt
        sender
        sk
      }
      nextToken
    }
  }
`;

export const ChatQueries = { ChatSubsriptions, SendChat, GetAllChat, GetAllDMs, GetAllRoom };
